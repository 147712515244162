import React, { useState } from "react";
import "./style.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import WholePageLoader from "./WholePageLoader";

const CareerPageService = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [jobOpenings, setJobOpenings] = useState([
    {
      title: "Human Resource Executive",
      experience: "2-4 years",
      location: "Remote",
      selectedFile: null,
      fileSubmitted: false,
      fileResponse: "",
      shortName: "HR",
    },
    {
      title: "Front End Developer (React Js)",
      experience: "3-5 years",
      location: "Remote",
      selectedFile: null,
      fileSubmitted: false,
      fileResponse: "",
      shortName: "FE",
    },
    {
      title: "Back End Developer (Java)",
      experience: "2-4 years",
      location: "Remote",
      selectedFile: null,
      fileSubmitted: false,
      fileResponse: "",
      shortName: "BE",
    },
    {
      title: "DevOps Engineer",
      experience: "2-5 years",
      location: "Remote",
      selectedFile: null,
      fileSubmitted: false,
      fileResponse: "",
      shortName: "DO",
    },
    // Add more job openings as needed
  ]);

  const handleFileChange = (event, index) => {
    const updatedJobOpenings = [...jobOpenings];
    updatedJobOpenings[index].selectedFile = event.target.files[0];
    setJobOpenings(updatedJobOpenings);
  };

  const clearFile = (index) => {
    const updatedJobOpenings = [...jobOpenings];
    updatedJobOpenings[index].selectedFile = null;
    setJobOpenings(updatedJobOpenings);
  };

  const handleSubmit = async (index) => {
    setLoading(true);
    const updatedJobOpenings = [...jobOpenings];
    const selectedFile = updatedJobOpenings[index].selectedFile;

    if (!selectedFile) {
      setLoading(false);
      return (
        <div className="text-danger">
          Please select a file before submitting.
        </div>
      );
    }

    const formdata = new FormData();
    formdata.append("file", selectedFile);

    try {
      const response = await axios.post(
        "https://api.zubisko.in/upload",
        formdata
      );

      if (response.data) {
        updatedJobOpenings[index].fileSubmitted = true;
        updatedJobOpenings[index].fileResponse = response.data;
        setLoading(false);
      } else {
        updatedJobOpenings[index].fileResponse = response.data;
        setLoading(false);
      }

      setTimeout(() => {
        history.push("/");
      }, 3000);
    } catch (error) {
      updatedJobOpenings[index].fileResponse = "Upload Failed";
      updatedJobOpenings[index].fileSubmitted = false;
      setLoading(false);
    }

    setJobOpenings(updatedJobOpenings);
  };

  return (
    <section
      id="sectionJobList"
      className="section section-job-list gradient-light--lean-left"
    >
      {loading && <WholePageLoader />}
      <div className="container">
        <div className="row row-grid justify-content-center">
          <div className="col-md-8 col-lg-7 col-xl-6 text-center">
            <h2 className="section__title mb-4 mt-5">
              Job Openings at Zubisko
            </h2>

            <p>
              We’re always searching for amazing people to join our team. Take a
              look at our current openings.
            </p>
          </div>

          <div className="mt-5 col-md-12">
            {jobOpenings.map((job, index) => (
              <div className="col-md-12 mt-3" key={index}>
                <div className="job-list__wrapper mb-6">
                  {/* <h3 className="mb-4">{job.title}</h3> */}

                  <div className="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                    <div className="card-body">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-4 color--heading">
                          <div className="d-flex align-items-center">
                            <span className="fs-5 badge badge-circle background--success text-white mr-3 d-flex align-items-center justify-content-center">
                              {job.shortName}
                            </span>
                            <span className="d-flex align-items-center justify-content-center">
                              {job.title}
                            </span>
                          </div>
                        </div>

                        <div className="col-6 col-md-2 my-3 my-sm-0 color--text">
                          <i className="fas fa-clock mr-1"></i> {job.experience}
                        </div>

                        <div className="col-6 col-md-2 my-3 my-sm-0 color--text">
                          <i className="fas fa-map-marker-alt mr-1"></i>{" "}
                          {job.location}
                        </div>

                        <div className="d-flex justify-content-center col-6 col-md-2 my-3 my-sm-0 color--text">
                          {job.selectedFile ? (
                            <div>
                              {!job.fileSubmitted && (
                                <button
                                  type="button"
                                  className="custom-file-upload"
                                  onClick={() => clearFile(index)}
                                >
                                  Change File
                                </button>
                              )}
                              <div className="file-name">
                                {job.selectedFile.name.length > 10
                                  ? `${job.selectedFile.name.substring(
                                      0,
                                      10
                                    )}...`
                                  : job.selectedFile.name}
                              </div>
                            </div>
                          ) : !job.fileSubmitted ? (
                            <label
                              htmlFor={`file-upload-${index}`}
                              className="custom-file-upload"
                            >
                              Upload CV
                              <input
                                type="file"
                                id={`file-upload-${index}`}
                                style={{ display: "none" }}
                                accept=".pdf, .doc, .docx"
                                onChange={(event) =>
                                  handleFileChange(event, index)
                                }
                              />
                            </label>
                          ) : null}
                        </div>
                        <div className="col-6 col-md-2 my-3 my-sm-0 color--text text-center">
                          {job.fileSubmitted ? (
                            <div className="text-success">
                              {job.fileResponse}
                            </div>
                          ) : job.selectedFile ? (
                            <button
                              className={`custom-file-upload ${
                                job.selectedFile ? "" : "disabled-button"
                              }`}
                              onClick={() => handleSubmit(index)}
                            >
                              Submit File
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerPageService;
