import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
function IntroSlider() {
  return (
    <div style={{ paddingTop: "100px" }}>
      <div
        id="carouselExampleInterval"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide-to="0"
            className="active"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide-to="1"
            aria-label="Slide 2"
            className=""
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide-to="2"
            aria-label="Slide 3"
            className=""
            aria-current="true"
          ></button>
        </div>
        <div className="carousel-inner">
          <div
            className="carousel-item active bg-image-1"
            data-bs-interval="4000"
          >
            <div className="carousel-caption  d-md-block">
              <Link to="/service">
                <button className="custom-btn btn-12">Explore Here</button>
              </Link>
            </div>
          </div>
          <div className="carousel-item bg-image-2" data-bs-interval="4000">
            <div className="carousel-caption  d-md-block">
              <Link to="/hardware-software-products">
                <button className="custom-btn btn-12">Explore Here</button>
              </Link>
            </div>
          </div>
          <div className="carousel-item bg-image-3">
            <div className="carousel-caption  d-md-block">
              <Link to="/service">
                <button className="custom-btn btn-12">Explore Here</button>
              </Link>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default IntroSlider;
