import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactFrom = () => {
    const history = useHistory()
    const [user, setUser] = useState({
        name:"", email:"", phone:"", comments:""
    })
    const [disabled, setDisabled] = useState(false)
    let name,value;
    const handleInputs = (e) => {
        name = e.target.name
        value = e.target.value

        setUser({ ...user, [name]:value})
    }

    const PostData = async (e) => {
        setDisabled(true)
        e.preventDefault()

        const { name, email, phone, comments } = user
        const res = await fetch("https://api.zubisko.in/register", {
           method: "POST",
           headers: {
               "Content-Type" : "application/json"
           },
           body: JSON.stringify({
               name, email, phone, comments
           })
       })
        const data = await res.json()

        if(data.error) {
            setDisabled(false)
            toast.error('Failed to submit. Try again!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }else{
            toast.success("Submitted successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                }); 
            setTimeout(()=> {
                history.push("/")
            }, 5000)
        }

    }

    return (
        <>
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
            <form method="POST">
                <div className="row mb-n6">
                    <div className="col-md-6 col-12 mb-6">
                        <input type="text" placeholder="Your Name *" name="name" value={user.name} onChange={handleInputs} required />

                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="email" placeholder="Email *" name="email" value={user.email} onChange={handleInputs} required />

                    </div>
                    <div className="col-md-12 col-12 mb-6">
                        <input type="text" placeholder="Please enter your 10 digit phone number" name="phone" value={user.phone} onChange={handleInputs} required/>

                    </div>
                    <div className="col-12 mb-6">
                        <textarea name="comments" placeholder="Message" value={user.comments} onChange={handleInputs}  required/>

                    </div>
                    <div className="col-12 text-center mb-6">
                        <button type="submit" name="submit" disabled={disabled} className="btn btn-primary btn-hover-secondary" onClick={PostData}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <ToastContainer position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark" 
        />
        </>
    )
}

export default ContactFrom;
