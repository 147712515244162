import React from "react";
import SEO from "../components/SEO";
import NotFoundPage from "../container/NotFound/NotFoundPage";
const NotFound = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || 404" />
      <NotFoundPage />
    </React.Fragment>
  );
};

export default NotFound;
