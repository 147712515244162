import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import CareerPageService from "../container/CareerPage/CareerPageService";

const CareerPage = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || Career" />
      <Header />
      <CareerPageService />
      <ContactInformation />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default CareerPage;
