import PropTypes from "prop-types";
import React from 'react';
import "./_sectionstyle.scss";

const multiColorSentenceStyles = {
  background: "linear-gradient(45deg, #d27701,#e95207,#FF5500,#F20D0D,#d64415,#b92e2f,#b52d32,#9e3544,#4f5381)",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  display: "inline-block",
  };
const SectionTitle = ({ title, subTitle, titleOption, headingOption }) => {
    return (
        <div className={`section-title ${titleOption}`} data-aos="fade-up">
            <h2 style={multiColorSentenceStyles} className={`title ${headingOption} colorful`} dangerouslySetInnerHTML={{__html: title}}></h2>
            <p className="sub-title">{subTitle}</p>
        </div>
    )
}

SectionTitle.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    titleOption: PropTypes.string,
    headingOption: PropTypes.string
};
SectionTitle.defaultProps = {
    titleOption: "text-center",
    headingOption: "title"
};

export default SectionTitle;
