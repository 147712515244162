import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import AimlServices from "../container/AimlService/AimlServices";

const AimlService = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || AI/ML Service" />
      <Header />
      <AimlServices />
      <ContactInformation />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AimlService;
