import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import PrivacyContainer from "../container/Privacy/Privacy";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Privacy = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || Privacy" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        title="At Zubisko, we're committed to your digital well-being"
        content="Home"
        contentTwo="Privacy"
      />
      <PrivacyContainer />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Privacy;
