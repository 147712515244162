import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import ServiceIconBoxThree from "../container/service/ServiceIconBoxThree";

const HardwareServices = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || Hardware Software Products" />
      <Header />
      <ServiceIconBoxThree />
      <ContactInformation />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default HardwareServices;
