import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import ServiceIconBoxTwo from "../container/service/ServiceIconBoxTwo";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const webServices = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || Web Services" />
      <Header />

      <ServiceIconBoxTwo />
      <ContactInformation />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default webServices;
