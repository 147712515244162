import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const consentStyle = {
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
  background: "#e8ecf7",
  color: "#000",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.1)",
};

const buttonStyle = {
  background: "#181818",
  color: "white",
  fontSize: "13px",
  padding: "8px 16px",
  border: "none",
  cursor: "pointer",
  borderRadius: "4px",
};

const linkStyle = {
  color: "#181818",
  fontWeight: "bold",
  textDecoration: "underline",
};

const CookieConsents = () => {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="zubiskoConsent"
        style={consentStyle}
        buttonStyle={buttonStyle}
        expires={150}
      >
        We utilize cookies to enhance your browsing experience and to analyze
        website traffic. By continuing to use this site, you agree to our{" "}
        <Link to={`${process.env.PUBLIC_URL}/privacy`} style={linkStyle}>
          Privacy
        </Link>
        &nbsp;and&nbsp;
        <Link to={`${process.env.PUBLIC_URL}/cookiepolicy`} style={linkStyle}>
          Cookie
        </Link>
        &nbsp;policy.
      </CookieConsent>
    </div>
  );
};

export default CookieConsents;
