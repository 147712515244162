import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ServiceIconBox from "../container/service/ServiceIconBox";
// import ServiceSkill from "../container/service/ServiceSkill";
import AboutSix from "../container/About/AboutSix";
// import FunfactTwo from "../container/Funfact/FunfactTwo";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Service = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || Service" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        title="Services that shape your digital dreams into reality"
        content="Home"
        contentTwo="Services"
      />

      <ServiceIconBox />
      {/* <ServiceSkill /> */}
      <AboutSix />
      {/* <FunfactTwo /> */}
      <ContactInformation />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Service;
