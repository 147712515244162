import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle.jsx";
import { Link } from "react-router-dom";

const PrivacyContainer = () => {
  return (
    <div className="section section-padding bg-primary-blue">
      <div className="container">
        <SectionTitle
          titleOption="text-center mb-4"
          title="Privacy Policy"
          subTitle=""
        />

        <div className="row">
          <div className="col-lg-8 col-md-10 col-12 mx-auto mt-5">
            <p>
              Thank you for visiting{" "}
              <strong style={{ color: "#000" }}>Zubisko Technologies</strong>.
              Your privacy is important to us, and we are committed to protect
              your personal information. This Privacy Policy outlines the types
              of data we collect, how we use and safeguard it, and your rights
              regarding your information.
            </p>

            <h4 className="mt-5">1. Information We Collect</h4>
            <p>
              <strong style={{ color: "#000" }}>
                a. Personal Information: &nbsp;
              </strong>
              We may collect personal information such as your name, email
              address, postal address, and phone number when you provide it
              voluntarily, such as when you fill out a contact form or make a
              purchase.
            </p>
            <p>
              <strong style={{ color: "#000" }}>b. Log Data: &nbsp;</strong> Our
              servers may automatically collect data when you visit our website,
              including your IP address, browser type, and referring website.
            </p>

            <h4>2. How We Use Your Information</h4>
            <p>
              <strong style={{ color: "#000" }}>
                a. Communication: &nbsp;
              </strong>{" "}
              We may use your personal information to respond to your inquiries,
              send you updates about our services, and provide customer support.
            </p>
            <p>
              <strong style={{ color: "#000" }}>b. Analytics: &nbsp;</strong> We
              collect log data to understand how visitors interact with our
              website and to improve our services.
            </p>

            <h4>3. Data Sharing</h4>
            <p>
              We do not sell, trade, or rent your personal information to third
              parties. We may share your information with trusted service
              providers who assist us in operating our website and delivering
              services. These service providers are required to maintain the
              confidentiality of your information.
            </p>

            <h4>4. Cookies</h4>
            <p>
              We use cookies to collect information about your browsing behavior
              and preferences. You can control cookies through your browser
              settings. For more information, please read our{" "}
              <Link to="/cookiepolicy">
                <strong>Cookie Policy</strong>
              </Link>
              .
            </p>

            <h4>5. Security</h4>
            <p>
              We take security seriously and employ reasonable measures to
              protect your personal information. However, no method of
              transmission or storage on the internet is completely secure.
            </p>

            <h4>6. Your Rights</h4>
            <p>
              <strong style={{ color: "#000" }}>
                a. Access and Correction: &nbsp;
              </strong>{" "}
              You have the right to access and correct your personal
              information. If you would like to review or update your
              information, please contact us at{" "}
              <strong>
                <a href="mailto:support@zubisko.in">support@zubisko.in</a>
              </strong>
              .
            </p>
            <p>
              <strong style={{ color: "#000" }}>b. Opt-Out: &nbsp;</strong> You
              can opt out of receiving marketing communications from us by
              following the instructions in the emails or contacting us
              directly.
            </p>

            <h4>7. Third-Party Links</h4>
            <p>
              Our website may contain links to third-party websites. We are not
              responsible for the privacy practices or content of these
              websites. We recommend reviewing their privacy policies before
              providing any personal information.
            </p>

            <h4>8. Children&rsquo;s Privacy</h4>
            <p>
              Our website is not directed at individuals under the age of 13,
              and we do not knowingly collect personal information from
              children.
            </p>

            <h4>9. Changes to This Privacy Policy</h4>
            <p>
              We may update this Privacy Policy to reflect changes in our
              practices or for legal reasons. Please check this page for the
              most current version. If we make significant changes, we will
              notify you by email or through a notice on our website.
            </p>

            <h4>10. Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at{" "}
              <strong style={{ color: "#000" }}>
                <a href="mailto:support@zubisko.in">support@zubisko.in</a>
              </strong>
            </p>

            <p>
              By using our website, you agree to the terms of this Privacy
              Policy. If you do not agree with these terms, please refrain from
              using our website.
            </p>

            <strong style={{ color: "#000" }}>
              Zubisko Technologies
              <br />
              No. 303, Neb Sarai, Saket
              <br />
              New Delhi, Delhi, 110068
              <br />
              support@zubisko.in
              <br />
              7415930090
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyContainer;
