import React, { useState } from "react";
import "./style.css";

import Java from "./images/web/java.png";
import Net from "./images/web/net.png";
import Python from "./images/web/python.png";
import Node from "./images/web/nodejs.png";
import Go from "./images/web/go.png";

import Php from "./images/web/php.png";
import JS from "./images/web/js.png";
import REACT from "./images/web/react.png";
import ANG from "./images/web/ang.png";
import VUE from "./images/web/vue.png";
import HTML from "./images/web/html.png";
import CSS from "./images/web/css.png";
import JEN from "./images/Dev/jen.png";
import ANSI from "./images/Dev/ansi.png";
import TER from "./images/Dev/ter.png";
import DOCK from "./images/Dev/dock.png";
import KUBER from "./images/Dev/kuber.png";

import IOS from "./images/mobile/ios.png";
import FLUT from "./images/mobile/flut.png";
import AND from "./images/mobile/android.png";

import GCP from "./images/cloud/gcp.png";
import AWS from "./images/cloud/aws.png";
import DIGITAL from "./images/cloud/digital.png";
import AZURE from "./images/cloud/azure.png";
import ORACLE from "./images/cloud/oracle.png";

import MONGO from "./images/database/mongo.png";
import POST from "./images/database/post.png";
import SQL from "./images/database/sql.png";
import MYSQL from "./images/database/mysql.png";
import REDIS from "./images/database/redis.png";

import TENSOR from "./images/ml/tensor.png";
import OPENCV from "./images/ml/opencv.png";

const LanguageComponent = () => {
  const [activeTab, setActiveTab] = useState("v-pills-home");

  const handleTabChange = (tabId, e) => {
    e.preventDefault();
    setActiveTab(tabId);
  };

  return (
    <section className="py-5 header">
      <div className="container py-4">
        <div className="tabchange row row-grid  justify-content-center">
          <div className="col-md-12 col-lg-9 col-xl-9 text-center">
            <h1 className="section__title mb-4">
              Technologies and Platforms We Work With
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div
              className="nav flex-column nav-pills nav-pills-custom"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                href="#v-pills-home"
                className={`nav-link mb-3 p-3 shadow ${
                  activeTab === "v-pills-home" ? "active" : ""
                }`}
                id="v-pills-home-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected={activeTab === "v-pills-home"}
                onClick={(e) => handleTabChange("v-pills-home", e)}
              >
                <span className="font-weight-bold small text-uppercase">
                  WEB
                </span>
              </a>

              <a
                href="#v-pills-profile"
                className={`nav-link mb-3 p-3 shadow ${
                  activeTab === "v-pills-profile" ? "active" : ""
                }`}
                id="v-pills-profile-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={activeTab === "v-pills-profile"}
                onClick={(e) => handleTabChange("v-pills-profile", e)}
              >
                <span className="font-weight-bold small text-uppercase">
                  MOBILE
                </span>
              </a>

              <a
                href="#v-pills-messages"
                className={`nav-link mb-3 p-3 shadow ${
                  activeTab === "v-pills-messages" ? "active" : ""
                }`}
                id="v-pills-messages-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected={activeTab === "v-pills-messages"}
                onClick={(e) => handleTabChange("v-pills-messages", e)}
              >
                <span className="font-weight-bold small text-uppercase">
                  CLOUD
                </span>
              </a>

              <a
                href="#v-pills-settings"
                className={`nav-link mb-3 p-3 shadow ${
                  activeTab === "v-pills-settings" ? "active" : ""
                }`}
                id="v-pills-settings-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected={activeTab === "v-pills-settings"}
                onClick={(e) => handleTabChange("v-pills-settings", e)}
              >
                <span className="font-weight-bold small text-uppercase">
                  DATABASES
                </span>
              </a>
              <a
                href="#v-pills-ml"
                className={`nav-link mb-3 p-3 shadow ${
                  activeTab === "v-pills-ml" ? "active" : ""
                }`}
                id="v-pills-ml-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-ml"
                aria-selected={activeTab === "v-pills-ml"}
                onClick={(e) => handleTabChange("v-pills-ml", e)}
              >
                <span className="font-weight-bold small text-uppercase">
                  MACHINE LEARNING
                </span>
              </a>
              <a
                href="#v-pills-dv"
                className={`nav-link mb-3 p-3 shadow ${
                  activeTab === "v-pills-dv" ? "active" : ""
                }`}
                id="v-pills-dv-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="v-pills-dv"
                aria-selected={activeTab === "v-pills-dv"}
                onClick={(e) => handleTabChange("v-pills-dv", e)}
              >
                <span className="font-weight-bold small text-uppercase">
                  DEVOPS
                </span>
              </a>
            </div>
          </div>

          <div className="col-md-9">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className={`tab-pane fade shadow rounded bg-white p-5 ${
                  activeTab === "v-pills-home" ? "show active" : ""
                }`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <h4 className="font-italic mb-4">Backend</h4>
                <div className="col-md-10 font-italic text-muted mb-2">
                  <img
                    src={Java}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={Net}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={Python}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={Node}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={Go}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={Php}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </div>
                <h4 className="font-italic mb-4 mt-5">Frontend</h4>
                <p className="font-italic text-muted mb-2">
                  <img
                    src={HTML}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={CSS}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={JS}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={REACT}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={ANG}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={VUE}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </p>
              </div>

              <div
                className={`tab-pane fade shadow rounded bg-white p-5 ${
                  activeTab === "v-pills-profile" ? "show active" : ""
                }`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="col-md-10 font-italic text-muted mb-2">
                  <img
                    src={FLUT}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={AND}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={IOS}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={REACT}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </div>
              </div>

              <div
                className={`tab-pane fade shadow rounded bg-white p-5 ${
                  activeTab === "v-pills-messages" ? "show active" : ""
                }`}
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div className="col-md-10 font-italic text-muted mb-2">
                  <img
                    src={GCP}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={AWS}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={AZURE}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={DIGITAL}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={ORACLE}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </div>
              </div>

              <div
                className={`tab-pane fade shadow rounded bg-white p-5 ${
                  activeTab === "v-pills-settings" ? "show active" : ""
                }`}
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="col-md-10 font-italic text-muted mb-2">
                  <img
                    src={MONGO}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={POST}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={SQL}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={MYSQL}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={ORACLE}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={REDIS}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </div>
              </div>
              <div
                className={`tab-pane fade shadow rounded bg-white p-5 ${
                  activeTab === "v-pills-ml" ? "show active" : ""
                }`}
                id="v-pills-ml"
                role="tabpanel"
                aria-labelledby="v-pills-ml-tab"
              >
                <div className="col-md-10 font-italic text-muted mb-2">
                  <img
                    src={TENSOR}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={OPENCV}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </div>
              </div>
              <div
                className={`tab-pane fade shadow rounded bg-white p-5 ${
                  activeTab === "v-pills-dv" ? "show active" : ""
                }`}
                id="v-pills-dv"
                role="tabpanel"
                aria-labelledby="v-pills-dv-tab"
              >
                <div className="col-md-10 font-italic text-muted mb-2">
                  <img
                    src={JEN}
                    alt="Java"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={ANSI}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={DOCK}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={KUBER}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                  <img
                    src={TER}
                    alt=".NET"
                    className="img-fluid mr-md-10 mr-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LanguageComponent;
