
import React from 'react';
import {Link} from "react-router-dom";
import logo from './logo.svg.jpg';

const Logo = () => {
    const imageStyle = {
        height: "75px",
    }
    return(
        <div>
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img style={imageStyle} className="dark-logo" src={logo} alt="Agency Logo" />
                {/* <p style={logoStyle}>Zubisko</p> */}
            </Link>
        </div>
    )
}


export default Logo;
