import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import CookieConsents from "./components/CookieConsents";

import HomeOne from "./pages/HomeOne";
// import HomeTwo from './pages/HomeTwo';
// import HomeThree from './pages/HomeThree';
import About from "./pages/About";
import Service from "./pages/Service";
import Work from "./pages/Work";
import HardwareServices from "./pages/HardwareServices";
import DevOps from "./pages/DevOps";
import CloudService from "./pages/CloudService";
//import WorkDetails from './pages/WorkDetails';
// import BlogGrid from './pages/BlogGrid';
// import BlogClassic from './pages/BlogClassic';
// import BlogDetails from './pages/BlogDetails';
// import BlogCategories from './pages/BlogCategories';
// import BlogTag from './pages/BlogTag';
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import CookiePolicy from "./pages/CookiePolicy";
import webServices from "./pages/WebServices";
import AimlService from "./pages/Aiml";
import HireTeam from "./pages/HireTeam";
import CareerPage from "./pages/CareerPage";
import NotFound from "./pages/NotFound";

// CSS File Here
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <Router>
        <NavScrollTop>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              exact
              component={HomeOne}
            />
            {/*<Route path={`${process.env.PUBLIC_URL + "/home-one"}`} exact component={HomeOne}/>*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo}/>*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/home-three"}`} component={HomeThree}/>*/}
            <Route
              path={`${process.env.PUBLIC_URL + "/about"}`}
              component={About}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/service"}`}
              component={Service}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/it-products"}`}
              component={Work}
            />
            {/*<Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} component ={WorkDetails} />*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component ={BlogGrid} />*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component ={BlogClassic} />*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} component ={BlogTag} />*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`}component ={BlogCategories} />*/}
            {/*<Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}component ={BlogDetails} />*/}
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              component={Contact}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/privacy"}`}
              component={Privacy}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/cookiepolicy"}`}
              component={CookiePolicy}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/webservices"}`}
              component={webServices}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/hardware-software-products"}`}
              component={HardwareServices}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/dev-ops"}`}
              component={DevOps}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/cloudservice"}`}
              component={CloudService}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/ai-ml"}`}
              component={AimlService}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/hireteam"}`}
              component={HireTeam}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/careers"}`}
              component={CareerPage}
            />
            <Route component={NotFound} />
          </Switch>
        </NavScrollTop>
        <CookieConsents />
      </Router>
    </>
  );
}

export default App;
