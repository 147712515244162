import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">What does your company specialize in?</AccordionTitle>
                    <AccordionContent id="one">We specialize in providing high-quality computer hardware, software solutions & website development, catering to a wide range of needs, from personal to enterprise applications.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">Do you provide customized hardware solutions for specific needs?</AccordionTitle>
                    <AccordionContent id="two">Yes, we offer customization options to meet specific hardware requirements. Please contact our sales team for more information.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">Do you offer any discounts or promotions for bulk orders?</AccordionTitle>
                    <AccordionContent id="three">We often have promotions and discounts for bulk orders. Check our website or contact our sales team for current offers.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="four">
                    <AccordionTitle id="four">What sets your computer hardware and software apart from competitors?</AccordionTitle>
                    <AccordionContent id="four">Our commitment to innovation, quality, and customer satisfaction sets us apart. We constantly strive to offer cutting-edge technology and exceptional customer support.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="nine">
                    <AccordionTitle id="nine">How can your web development company help me create and maintain my website?</AccordionTitle>
                    <AccordionContent id="nine">
                    <h3>At Zubisko Technologies</h3>
                        <p>We offer a comprehensive suite of web development services to help you create, launch, and maintain a successful website. Our expertise spans a wide range of areas to ensure your online presence is both effective and hassle-free. Here&apos;s how we can assist you:</p>

                        <h4>Website Design</h4>
                        <p>Our experienced designers will work closely with you to create a visually appealing and user-friendly website that aligns with your brand and goals.</p>

                        <h4>Custom Development</h4>
                        <p>We build websites from the ground up, tailoring the functionality to meet your specific requirements. Whether it&apos;s an e-commerce site, a blog, or a corporate website, we have the technical skills to deliver.</p>

                        <h4>Responsive Design</h4>
                        <p>We ensure that your website is responsive, meaning it adapts to various screen sizes and devices, providing an optimal user experience.</p>

                        <h4>Content Management</h4>
                        <p>We can integrate content management systems (CMS) such as WordPress or custom solutions, allowing you to easily update and manage your website&apos;s content.</p>

                        <h4>E-Commerce Solutions</h4>
                        <p>If you&apos;re looking to sell products or services online, we can implement secure and efficient e-commerce solutions.</p>

                        <h4>SEO Integration</h4>
                        <p>We optimize your website for search engines to improve its visibility and rankings in search results.</p>

                        <h4>Ongoing Maintenance</h4>
                        <p>We offer ongoing website maintenance services to keep your site up-to-date, secure, and functioning smoothly. This includes regular updates, security monitoring, and backup services.</p>

                        <h4>24/7 Support</h4>
                        <p>Our customer support team is available to assist you with any issues or questions you may have regarding your website.</p>

                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="five">
                    <AccordionTitle id="five">What payment methods do you accept?</AccordionTitle>
                    <AccordionContent id="five">We accept payments through major credit cards, PayPal, and other secure online payment methods.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="six">
                    <AccordionTitle id="six">How can I place an order for your products?</AccordionTitle>
                    <AccordionContent id="six">You can place an order on our website or contact our sales team for assistance.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="seven">
                    <AccordionTitle id="seven">How long does it take for orders to be shipped?</AccordionTitle>
                    <AccordionContent id="seven">Orders are typically shipped within [1-2] business days. Shipping times may vary based on your location and shipping method.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="eight">
                    <AccordionTitle id="eight">What should I do if my order arrives damaged?</AccordionTitle>
                    <AccordionContent id="eight">If your order arrives damaged, please contact our customer support team immediately. We will assist you with the return or replacement process.</AccordionContent>
                </AccordionItem>
                
                {/* <AccordionItem id="ten">
                    <AccordionTitle id="ten">Do you offer any discounts or promotions for bulk orders?</AccordionTitle>
                    <AccordionContent id="ten">We often have promotions and discounts for bulk orders. Check our website or contact our sales team for current offers.</AccordionContent>
                </AccordionItem> */}
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
