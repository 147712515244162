import PropTypes from "prop-types";
import React,{useEffect} from 'react';
import ReactVivus from 'react-vivus';
import {Link} from "react-router-dom";
import $ from "jquery"

const IconBox = ({ data, classOption }) => {
    useEffect(() => {
        const iconBoxes = $('.icon-box');
    
        // Find the tallest element's height
        let maxHeight = 0;
        iconBoxes.each(function () {
          const currentHeight = $(this).height();
          if (currentHeight > maxHeight) {
            maxHeight = currentHeight;
          }
        });
        iconBoxes.height(maxHeight);
      }, []); 
    
    return (
        <div className={`icon-box text-center ${classOption}`}>
            <div className="icon">
                <ReactVivus
                    id={`servicesvg-${data.id}`}
                    option={{
                        file: data.icon,
                        animTimingFunction: 'EASE',
                            type: 'oneByOne',
                            delay: 80
                    }}
                />
            </div>
            <div className="content">
                <h3 className="title">{data.title}</h3>
                <div className="desc">
                    <p>{data.desc}</p>
                    <Link to={data.link} className="link">{data.pageLink}</Link>
                </div>
                {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
            </div>
        </div>
    )
}

IconBox.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

IconBox.defaultProps = {
    classOption: "icon-box text-center",
};

export default IconBox;
