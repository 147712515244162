import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
const NotFoundPage = () => {
  return (
    <section className="section page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-md-12 col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Looks like you are lost!</h3>
                <Link to="/" className="link_404">
                  Go to Home
                </Link>
              </div>
              <div className="col-md-12 col-sm-10 col-sm-offset-1 error-reason">
                <ol>
                  <li>
                    <strong>Double-check the URL:</strong> Ensure that
                    you&rsquo;ve entered the correct website address or page
                    URL.
                  </li>
                  <li>
                    <strong>Check for typos:</strong> Make sure there are no
                    spelling mistakes or extra characters in the URL.
                  </li>
                  <li>
                    <strong>Search the website:</strong> Use the website&rsquo;s
                    search feature or navigation menu to find the content
                    you&rsquo;re looking for.
                  </li>
                  <li>
                    <strong>Contact website support:</strong> If you believe the
                    page should exist or if you&rsquo;re encountering the issue
                    on a reputable website, you can reach out to their support
                    or customer service for assistance.
                  </li>
                  <li>
                    <strong>Refresh or reload the page:</strong> Sometimes, a
                    temporary glitch can cause this message to appear. Reloading
                    the page may resolve the issue.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
