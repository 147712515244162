import React from "react";

const GoogleMap = () => {
  return (
    <div className="google-map-area section text-center section-padding-bottom">
      <div className="container">
        <div className="contact-map-area" data-aos="fade-up">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112209.94073294212!2d77.07163693755518!3d28.492775206818287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce127037b39ff%3A0xb01d38a9bb87164!2sZubisko%20Technologies!5e0!3m2!1sen!2sin!4v1697265185306!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
