import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CookiePolicysite from "../container/CookiePolicy/CookiePolicysite";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const CookiePolicy = () => {
  return (
    <React.Fragment>
      <SEO title="Zubisko || Cookie Policy" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        title="Cookies make the online world a better place, but it's your call if you want to take a bite"
        content="Home"
        contentTwo="Cookie Policy"
      />
      <CookiePolicysite />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default CookiePolicy;
