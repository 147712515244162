import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle.jsx";

const CookiePolicysite = () => {
  return (
    <div className="section section-padding bg-primary-blue">
      <div className="container">
        <SectionTitle
          titleOption="text-center mb-4"
          title="Cookie Policy"
          subTitle=""
        />

        <div className="row">
          <div className="col-lg-8 col-md-10 col-12 mx-auto mt-5">
            <h2>What Are Cookies?</h2>
            <p>
              Cookies are small text files that are placed on your computer by
              websites you visit. They are widely used to make websites work, or
              work more efficiently, as well as to provide information to the
              owners of the site.
            </p>

            <h2>How We Use Cookies</h2>
            <p>
              We use cookies for various purposes, including but not limited to:
            </p>
            <ul>
              <li>Analyzing website traffic and usage</li>
              <li>Improving website performance and user experience</li>
              <li>Remembering your preferences</li>
              <li>Displaying personalized content</li>
            </ul>

            <h2>Your Cookie Choices</h2>
            <p>
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. However, this
              may prevent you from taking full advantage of the website.
            </p>

            <h2>Third-Party Cookies</h2>
            <p>
              We may also use third-party services that may place cookies on
              your device. These third-party services have their own privacy
              policies and may collect and use your personal information. Please
              refer to their respective policies for more information.
            </p>

            <h2>Changes to Our Cookie Policy</h2>
            <p>
              We may update our Cookie Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. Please revisit this page regularly for the
              most up-to-date information on our use of cookies.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions about our Cookie Policy or the practices
              of this site, please contact us at{" "}
              <strong>
                <a href="mailto:support@zubisko.in">support@zubisko.in</a>
              </strong>
              .
            </p>
            <strong style={{ color: "#000" }}>
              Zubisko Technologies
              <br />
              No. 313, Neb Sarai, Saket
              <br />
              New Delhi, Delhi, 110068
              <br />
              support@zubisko.in
              <br />
              7415930090
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicysite;
